import { render, staticRenderFns } from "./Detail4Shop.vue?vue&type=template&id=538e7307&scoped=true"
import script from "./Detail4Shop.vue?vue&type=script&lang=js"
export * from "./Detail4Shop.vue?vue&type=script&lang=js"
import style0 from "./Detail4Shop.vue?vue&type=style&index=0&id=538e7307&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538e7307",
  null
  
)

export default component.exports